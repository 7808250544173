import { memo } from 'react'
import PropTypes from 'prop-types'
import { groupDataByDateInterval } from 'lib/dataGrouper'
import { Box, variants } from '@resident-advisor/design-system'
import useDateFnsLocale from '@/hooks/useDateFnsLocale'
import testIds from '@/enums/testIds'
import GroupedHeader from '@/components/generic/grouped-header'
import Sticky from '@/components/generic/sticky'

const MonthlyList = memo(({ Card, data, variant }) => {
  const locale = useDateFnsLocale()

  const groupedData = groupDataByDateInterval({
    data,
    dataKeyField: 'date',
    interval: groupDataByDateInterval.Intervals.MonthAndYear,
    locale,
  })

  return groupedData.map((group, groupIndex) => (
    <Box
      data-testid={testIds.monthlyList}
      key={group.key}
      pt={{
        s: getPadding(groupIndex, 3),
        m: getPadding(groupIndex, 5),
      }}
    >
      <Sticky data-testid={testIds.monthlyListSticky} variant={variant}>
        <GroupedHeader>{group.title}</GroupedHeader>
      </Sticky>
      {group.items.map((item) => (
        <Card key={item.id} {...item} />
      ))}
    </Box>
  ))
})

const isFirst = (index) => index === 0
const getPadding = (index, padding) => (isFirst(index) ? 0 : padding)

MonthlyList.propTypes = {
  Card: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  variant: PropTypes.string,
}

MonthlyList.defaultProps = {
  variant: variants.section.primary,
}

export default MonthlyList
